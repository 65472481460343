import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: "btn-icon",
    onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('onClick')), ["prevent"]))
  }, [
    _createElementVNode("img", {
      class: _normalizeClass([{ 'spin-animation': _ctx.animation }]),
      src: _ctx.image,
      alt: _ctx.icon
    }, null, 10, _hoisted_1)
  ]))
}