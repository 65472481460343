
import { Options, Vue } from "vue-class-component";

import Title from "@/components/Title.vue";
import Table from "@/components/Table/index.vue";
import TablePlaceholder from "@/components/Table/TablePlaceholder.vue";
import ButtonIcon from "@/components/Button/ButtonIcon.vue";

import FileUpload, { VueUploadItem } from "vue-upload-component";

import { timeStampToString } from "@/utils";
import { formatBytes } from "@/utils";
import { CustomSort } from "@/models/Sort";

@Options({
  props: {
    projectName: {
      type: String,
      required: true,
    },
    tableConfig: {
      type: Object,
    },
    loadingUploadFile: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: Array,
      default: () => [],
    },
    files: {
      type: Array,
      default: () => [],
    },
    fileRemove: {
      type: String,
      default: "",
    },
  },
  components: {
    Title,
    Table,
    TablePlaceholder,
    ButtonIcon,
    FileUpload,
  },
  inheritAttrs: false,
})
export default class Upload extends Vue {
  columns = [
    {
      label: "Name",
      key: "fileName",
      customWidth: "3fr",
    },
    {
      label: "Size",
      key: "fileSize",
    },
    {
      label: "Date",
      key: "fileCreatedAt",
      sort: true,
    },
    {
      label: "",
      key: "buttons_action",
      customWidth: "74px",
    },
  ];

  onUploadFiles(payload: VueUploadItem[]): void {
    this.$emit("onUploadFiles", payload);
  }

  formatDate(val: string): string {
    return timeStampToString(val);
  }

  formatBytesSize(val: number): string {
    return formatBytes(val);
  }

  onSortAction(payload: CustomSort): void {
    this.$emit("onSortAction", payload);
  }
}
